.css-b62m3t-container{
    width: 100%;
    outline: none;
}
.select_handler{
    position: relative;
    margin-bottom: 25px;
}
.select_handler_required{
    color: red;
    margin-right: 5px;
    font-size: 0.8rem;
    font-weight: lighter;
    text-align: start;
    margin-top: 2px;
}
.select_handler_label{
    margin-bottom: 0px;
}


@media (max-width:500px) {
    .select_handler .select_handler_required{
        font-size: 0.65rem;
    }
}