.InputHandler{
    position: relative;
    text-align: start;
    margin-bottom: 20px;
}
.InputHandler input{
    background-color: transparent;
    outline: none;
    border: 1px solid #8E9099 ;
    border-radius: 5px;
    font-size: 1rem;
    padding: 5px 10px;
    width: 100%;
    color: black;
}
.InputHandler .InputHandler_label{
    margin-bottom: 0px;
}
.InputHandler .InputHandler_required{
    margin-right: 5px;
    font-size: 0.8rem;
    color: red;
    font-weight: lighter;
}
.ShowPass{
    position: absolute;
    width: fit-content !important;
    padding:0px !important;
    left: 3%;
    top: 0%;
    transform: translateY(30%);
    cursor: pointer;
    color: gray;
}
@media (max-width:500px) {
    .InputHandler input{
        font-size: 0.7rem;
        padding: 8px 12px 8px 35px;
    }
    .ShowPass{
        top: 0%;
        transform: translateY(15%);
    }
    .InputHandler .InputHandler_label{
        font-size: 0.8rem;
    }
    .InputHandler .InputHandler_required{
        font-size: 0.65rem;
    }
    .InputHandler input{
        padding: 5px;
    }
}